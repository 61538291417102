import * as React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Seo from "../components/seo"

import "../style/index.scss"


const IndexPage = () => (
  <>
    <Seo />
    <Container fluid className="no-gutters">
      <Row className="fh align-items-center">
        <Col xs={"auto"} className="mx-auto">
          <div className="logo">
            {"Azerothian.Net"}
          </div>
        </Col>
      </Row>
    </Container>
  </>
)

export default IndexPage
